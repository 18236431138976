import Vue from 'vue';
import axios from 'axios';

let endpoint = process.env.API_ENDPOINT;

// Create the axios instance
const apiClient = axios.create({
    baseURL: endpoint + '/api/', // Base URL for your backend
    timeout: 30000,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
});

// Add a request interceptor to inject the token in every request
// apiClient.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token'); // Get the token from localStorage, Vuex, or cookies
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// Function to dynamically set the Authorization token
export const setAuthToken = (token) => {
    if (token) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete apiClient.defaults.headers.common['Authorization'];
    }
};

// Make the axios instance available as $api in all components
Vue.prototype.$api = apiClient;

export default apiClient;
